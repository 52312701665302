var mybutton = document.getElementById("myBtn");

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
    scrollFunction()
};

window.scrollFunction = function scrollFunction() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
    } else {
        mybutton.style.display = "none";
    }
}

// When the user clicks on the button, scroll to the top of the document
window.topFunction = function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

// end coding for go to top button 


//start coding for popup box open and close 
var element = document.getElementById("popup1");
var element1 = document.getElementById("popup");
var element2 = document.getElementById("iframepopup");

window.on1 = function on1() {
    // element1.classList.add("flexcontainerquotesec");
    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "block";
    document.getElementById("popup").style.display = "flex";
    document.getElementById("body").style.overflow = "hidden";

}

window.on = function on() {

    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "block";
    document.getElementById("popup").style.display = "flex";
    document.getElementById("body").style.overflow = "hidden";

}

window.oniframe = function oniframe() {
    element2.classList.add("flexcontainerframe");
    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "block";
    document.getElementById("iframepopup").style.display = "flex";
    document.getElementById("body").style.overflow = "hidden";
    document.getElementById("iframeplayer").src = "https://www.youtube.com/embed/AHRPRdpHuQg?autoplay=1";
}
window.oniframeunite = function oniframeunite() {
    element2.classList.add("flexcontainerframe");
    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "block";
    document.getElementById("iframepopup").style.display = "flex";
    document.getElementById("body").style.overflow = "hidden";
    document.getElementById("iframeuniteplayer").src = "https://www.youtube.com/embed/t1srcAddJf4?autoplay=1";
}
window.oniframemainpage = function oniframemainpage() {
    element2.classList.add("flexcontainerframe");
    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "block";
    document.getElementById("iframepopup").style.display = "flex";
    document.getElementById("body").style.overflow = "hidden";
    document.getElementById("iframemainpageplayer").src = "https://www.youtube.com/embed/XI-P34fIpqA?autoplay=1";
}
window.onschedule = function onschedule() {
    document.getElementById("overlay").style.display = "block";
    document.getElementById("close").style.display = "block";
    document.getElementById("popup1").style.display = "flex";
    // element.classList.remove("flexcontainerquotesec");
    document.getElementById("body").style.overflow = "hidden";


}

window.onschedule1 = function onschedule1() {
    // element.classList.add("flexcontainerquotesec");
    document.getElementById("close").style.display = "block";
    document.getElementById("overlay").style.display = "block";
    document.getElementById("popup1").style.display = "flex";
    document.getElementById("body").style.overflow = "hidden";

}

window.off = function off() {
    document.getElementById("body").style.overflow = "auto";
    document.getElementById("close").style.display = "none";
    document.getElementById("overlay").style.display = "none";
    document.getElementById("popup").style.display = "none";
    document.getElementById("popup1").style.display = "none";
    document.getElementById("iframepopup").style.display = "none";
    if (document.getElementById("iframeplayer")) {
        document.getElementById("iframeplayer").src = '';

    }
    if (document.getElementById("iframeuniteplayer")) {
        document.getElementById("iframeuniteplayer").src = '';

    }
    if (document.getElementById("iframemainpageplayer")) {
        document.getElementById("iframemainpageplayer").src = '';

    }

}

//end popup box open and close coding


// start coding for dropdown list

var x, i, j, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
for (i = 0; i < x.length; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
            /*when an item is clicked, update the original select box,
            and the selected item:*/
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
                if (s.options[i].innerHTML == this.innerHTML) {
                    s.selectedIndex = i;
                    h.innerHTML = this.innerHTML;
                    y = this.parentNode.getElementsByClassName("same-as-selected");
                    for (k = 0; k < y.length; k++) {
                        y[k].removeAttribute("class");
                    }
                    this.setAttribute("class", "same-as-selected");
                    break;
                }
            }
            h.click();
        });
        b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
    });
}

window.closeAllSelect = function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

// end coding for dropdown list

// start coding for dropdown list  for overlayform

var x, i, j, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-selectform");
for (i = 0; i < x.length; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];
    /*for each element, create a new DIV that will act as the selected item:*/
    a = document.createElement("DIV");
    a.setAttribute("class", "select-selectedform");
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    x[i].appendChild(a);
    /*for each element, create a new DIV that will contain the option list:*/
    b = document.createElement("DIV");
    b.setAttribute("class", "select-itemsform select-hide");
    for (j = 1; j < selElmnt.length; j++) {
        /*for each option in the original select element,
        create a new DIV that will act as an option item:*/
        c = document.createElement("DIV");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", function (e) {
            /*when an item is clicked, update the original select box,
            and the selected item:*/
            var y, i, k, s, h;
            s = this.parentNode.parentNode.getElementsByTagName("select")[0];
            h = this.parentNode.previousSibling;
            for (i = 0; i < s.length; i++) {
                if (s.options[i].innerHTML == this.innerHTML) {
                    s.selectedIndex = i;
                    h.innerHTML = this.innerHTML;
                    y = this.parentNode.getElementsByClassName("same-as-selected");
                    for (k = 0; k < y.length; k++) {
                        y[k].removeAttribute("class");
                    }
                    this.setAttribute("class", "same-as-selected");
                    break;
                }
            }
            h.click();
        });
        b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function (e) {
        /*when the select box is clicked, close any other select boxes,
        and open/close the current select box:*/
        e.stopPropagation();
        closeAllSelect(this);
        this.nextSibling.classList.toggle("select-hide");
        this.classList.toggle("select-arrow-active");
    });
}

window.closeAllSelect = function closeAllSelect(elmnt) {
    /*a function that will close all select boxes in the document,
    except the current select box:*/
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-itemsform");
    y = document.getElementsByClassName("select-selectedform");
    for (i = 0; i < y.length; i++) {
        if (elmnt == y[i]) {
            arrNo.push(i)
        } else {
            y[i].classList.remove("select-arrow-active");
        }
    }
    for (i = 0; i < x.length; i++) {
        if (arrNo.indexOf(i)) {
            x[i].classList.add("select-hide");
        }
    }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

// end coding for dropdown list for overlayform
//------------------HERE CHOOSE-----------------
// StartSwipeFeatureChoose();
// function StartSwipeFeatureChoose() {

//     console.log("MIHERE")

// }

// start why choose us index page manual coding
// window.currentSlide = function currentSlide(id) {
//     var firstBlock = document.getElementById('first5');
//     var secondBlock = document.getElementById('second5');
//     var thirdBlock = document.getElementById('third5');
//     var fourBlock = document.getElementById('four5');
//     var fiveBlock = document.getElementById('five5');

//     var oneBlock = document.getElementById('1V');
//     var twoBlock = document.getElementById('2V');
//     var threeBlock = document.getElementById('3V');
//     var foBlock = document.getElementById('4V');

//     var fiBlock = document.getElementById('5V');

//     if (id === 'first5') {
//         firstBlock.style.display = 'flex';
//         secondBlock.style.display = 'none';
//         thirdBlock.style.display = 'none';
//         fourBlock.style.display = 'none';
//         fiveBlock.style.display = 'none';

//         oneBlock.classList.add('dotactiveFive');
//         twoBlock.classList.remove('dotactiveFive');
//         threeBlock.classList.remove('dotactiveFive');
//         foBlock.classList.remove('dotactiveFive');
//         fiBlock.classList.remove('dotactiveFive');

//     } else if (id === 'second5') {
//         firstBlock.style.display = 'none';
//         secondBlock.style.display = 'flex';
//         thirdBlock.style.display = 'none';
//         fourBlock.style.display = 'none';
//         fiveBlock.style.display = 'none';

//         oneBlock.classList.remove('dotactiveFive');
//         twoBlock.classList.add('dotactiveFive');
//         threeBlock.classList.remove('dotactiveFive');
//         foBlock.classList.remove('dotactiveFive');
//         fiBlock.classList.remove('dotactiveFive');

//     } else if (id === 'third5') {
//         firstBlock.style.display = 'none';
//         secondBlock.style.display = 'none';
//         thirdBlock.style.display = 'flex';
//         fourBlock.style.display = 'none';
//         fiveBlock.style.display = 'none';


//         oneBlock.classList.remove('dotactiveFive');
//         twoBlock.classList.remove('dotactiveFive');
//         threeBlock.classList.add('dotactiveFive');
//         foBlock.classList.remove('dotactiveFive');
//         fiBlock.classList.remove('dotactiveFive');
//     } else if (id === 'four5') {
//         firstBlock.style.display = 'none';
//         secondBlock.style.display = 'none';
//         thirdBlock.style.display = 'none';
//         fourBlock.style.display = 'flex';
//         fiveBlock.style.display = 'none';


//         oneBlock.classList.remove('dotactiveFive');
//         twoBlock.classList.remove('dotactiveFive');
//         threeBlock.classList.remove('dotactiveFive');
//         foBlock.classList.add('dotactiveFive');
//         fiBlock.classList.remove('dotactiveFive');
//     } else if (id === 'five5') {
//         firstBlock.style.display = 'none';
//         secondBlock.style.display = 'none';
//         thirdBlock.style.display = 'none';
//         fourBlock.style.display = 'none';
//         fiveBlock.style.display = 'flex';


//         oneBlock.classList.remove('dotactiveFive');
//         twoBlock.classList.remove('dotactiveFive');
//         threeBlock.classList.remove('dotactiveFive');
//         foBlock.classList.remove('dotactiveFive');
//         fiBlock.classList.add('dotactiveFive');
//     }
// }


// end why choose us index page manual coding




// start our products(index) products auto coding

// window.sliderTimer1 = function sliderTimer1() {
//     this.setInterval(showSlide1, 4000);
// }
// window.addEventListener('load', () => {
//     sliderTimer1();
// })


// window.sliderTimer2 = function sliderTimer2() {
//     this.setInterval(currentSlidelivefeatures, 4000);
// }
// window.addEventListener('load', () => {
//     sliderTimer2();
// })



// start unite manual coding

// window.sliderTimer5 = function sliderTimer5() {
//     this.setInterval(currentSlideuniteauto, 4000);
// }
// window.addEventListener('load', () => {
//     sliderTimer5();
// })
// window.currentSlideuniteauto = function currentSlideuniteauto() {
//     var firstBlock = document.getElementById('first');
//     var secondBlock = document.getElementById('second');
//     var thirdBlock = document.getElementById('third');


//     var oneBlock = document.getElementById('1');
//     var twoBlock = document.getElementById('2');
//     var threeBlock = document.getElementById('3');

//     var firstdiv = document.getElementById("swipeChoose").firstElementChild;
//     var lastdiv = document.getElementById("swipeChoose").lastElementChild;
//     var listachievement = document.getElementById("swipeChoose"); // Get the <ul> element to insert a new node
//     lastdiv.insertAdjacentElement("afterend", firstdiv);
//     var idchoose = listachievement.children[0].id;

//     var isPaused = false;

//     if (!window.isPaused) {

//         if (idchoose === "first") {
//             firstBlock.style.display = 'flex';
//             secondBlock.style.display = 'none';
//             thirdBlock.style.display = 'none';


//             oneBlock.classList.add('dotactiveS');
//             twoBlock.classList.remove('dotactiveS');
//             threeBlock.classList.remove('dotactiveS');



//         } else if (idchoose === "second") {
//             firstBlock.style.display = 'none';
//             secondBlock.style.display = 'flex';
//             thirdBlock.style.display = 'none';

//             oneBlock.classList.remove('dotactiveS');
//             twoBlock.classList.add('dotactiveS');
//             threeBlock.classList.remove('dotactiveS');


//         } else if (idchoose === "third") {
//             firstBlock.style.display = 'none';
//             secondBlock.style.display = 'none';
//             thirdBlock.style.display = 'flex';


//             oneBlock.classList.remove('dotactiveS');
//             twoBlock.classList.remove('dotactiveS');
//             threeBlock.classList.add('dotactiveS');


//         }
//     }
// }
// window.currentSlideunite = function currentSlideunite(id) {
//     var firstBlock = document.getElementById('first');
//     var secondBlock = document.getElementById('second');
//     var thirdBlock = document.getElementById('third');


//     var oneBlock = document.getElementById('1');
//     var twoBlock = document.getElementById('2');
//     var threeBlock = document.getElementById('3');

//     var isPaused = false;
//     window.isPaused = true;

//     setTimeout(() => {
//         window.isPaused = false;
//     }, 15000);


//     if (id === '1') {
//         firstBlock.style.display = 'flex';
//         secondBlock.style.display = 'none';
//         thirdBlock.style.display = 'none';


//         oneBlock.classList.add('dotactiveS');
//         twoBlock.classList.remove('dotactiveS');
//         threeBlock.classList.remove('dotactiveS');



//     } else if (id === '2') {
//         firstBlock.style.display = 'none';
//         secondBlock.style.display = 'flex';
//         thirdBlock.style.display = 'none';

//         oneBlock.classList.remove('dotactiveS');
//         twoBlock.classList.add('dotactiveS');
//         threeBlock.classList.remove('dotactiveS');


//     } else if (id === '3') {
//         firstBlock.style.display = 'none';
//         secondBlock.style.display = 'none';
//         thirdBlock.style.display = 'flex';


//         oneBlock.classList.remove('dotactiveS');
//         twoBlock.classList.remove('dotactiveS');
//         threeBlock.classList.add('dotactiveS');


//     }

// }
// end unite section manual coding

// start  live streaming manual coding
// window.currentSlidelive = function currentSlidelive(id) {

//     if (id === 'first') {
//         document.getElementById('first').style.display = 'flex';
//         document.getElementById('second').style.display = 'none';
//         document.getElementById('third').style.display = 'none';


//         document.getElementById('1').classList.add('dotactiveS');
//         document.getElementById('2').classList.remove('dotactiveS');
//         document.getElementById('3').classList.remove('dotactiveS');



//     } else if (id === 'second') {
//         document.getElementById('first').style.display = 'none';
//         document.getElementById('second').style.display = 'flex';
//         document.getElementById('third').style.display = 'none';

//         document.getElementById('1').classList.remove('dotactiveS');
//         document.getElementById('2').classList.add('dotactiveS');
//         document.getElementById('3').classList.remove('dotactiveS');


//     } else if (id === 'third') {
//         document.getElementById('first').style.display = 'none';
//         document.getElementById('second').style.display = 'none';
//         document.getElementById('third').style.display = 'flex';


//         document.getElementById('1').classList.remove('dotactiveS');
//         document.getElementById('2').classList.remove('dotactiveS');
//         document.getElementById('3').classList.add('dotactiveS');


//     }
// }
// end live streaming section manual coding


// idtestimonials = "first2test";
// window.currentSlidetest = function currentSlidetest(id) {


//     var isPaused = false;

//     if (!window.isPaused) {
//         if (idtestimonials === "first2test" || id === 'first2test') {
//             document.getElementById('first2test').style.display = 'flex';
//             document.getElementById('second2test').style.display = 'none';


//             document.getElementById('12test').classList.add('dotactivetest');
//             document.getElementById('22test').classList.remove('dotactivetest');
//             idtestimonials = "second2test";



//         } else if (idtestimonials === "second2test" || id === 'second2test') {
//             document.getElementById('first2test').style.display = 'none';
//             document.getElementById('second2test').style.display = 'flex';

//             document.getElementById('12test').classList.remove('dotactivetest');
//             document.getElementById('22test').classList.add('dotactivetest');
//             idtestimonials = "first2test";


//         }
//     }
// }
// end live streaming features section manual coding

// start  hologram manual coding

// end hologram section manual coding


// start  creator choose manual coding
window.currentSlidecreatorfeatures = function currentSlidecreatorfeatures(id) {

    if (id === 'first') {
        document.getElementById('first').style.display = 'flex';
        document.getElementById('second').style.display = 'none';
        document.getElementById('third').style.display = 'none';


        document.getElementById('1').classList.add('dotactiveS');
        document.getElementById('2').classList.remove('dotactiveS');
        document.getElementById('3').classList.remove('dotactiveS');



    } else if (id === 'second') {
        document.getElementById('first').style.display = 'none';
        document.getElementById('second').style.display = 'flex';
        document.getElementById('third').style.display = 'none';

        document.getElementById('1').classList.remove('dotactiveS');
        document.getElementById('2').classList.add('dotactiveS');
        document.getElementById('3').classList.remove('dotactiveS');


    } else if (id === 'third') {
        document.getElementById('first').style.display = 'none';
        document.getElementById('second').style.display = 'none';
        document.getElementById('third').style.display = 'flex';


        document.getElementById('1').classList.remove('dotactiveS');
        document.getElementById('2').classList.remove('dotactiveS');
        document.getElementById('3').classList.add('dotactiveS');


    }
}


// end creator choose section manual coding

// end creator choose section manual coding

// start  analytics choose manual coding
// window.currentSlideanalytics = function currentSlideanalytics(id) {


//     if (id === 'firstred') {
//         document.getElementById('firstred').style.display = 'flex';
//         document.getElementById('secondred').style.display = 'none';
//         document.getElementById('thirdred').style.display = 'none';


//         document.getElementById('1red').classList.add('dotredactive');
//         document.getElementById('2red').classList.remove('dotredactive');
//         document.getElementById('3red').classList.remove('dotredactive');



//     } else if (id === 'secondred') {
//         document.getElementById('firstred').style.display = 'none';
//         document.getElementById('secondred').style.display = 'flex';
//         document.getElementById('thirdred').style.display = 'none';

//         document.getElementById('1red').classList.remove('dotredactive');
//         document.getElementById('2red').classList.add('dotredactive');
//         document.getElementById('3red').classList.remove('dotredactive');


//     } else if (id === 'thirdred') {
//         document.getElementById('firstred').style.display = 'none';
//         document.getElementById('secondred').style.display = 'none';
//         document.getElementById('thirdred').style.display = 'flex';


//         document.getElementById('1red').classList.remove('dotredactive');
//         document.getElementById('2red').classList.remove('dotredactive');
//         document.getElementById('3red').classList.add('dotredactive');


//     }
// }

// --------------------HERE----------------------------------------

StartSwipeFeature();

function StartSwipeFeature() {




    var mySwipeC = document.getElementById('swipeChoose');
    if (mySwipeC) {
        var mySwipeeC = new Hammer(mySwipeC, {
            touchAction: "pan-y"
        });

        mySwipeeC.get('pan').set({
            direction: Hammer.DIRECTION_ALL
        });

        var idd = '';


        mySwipeeC.on("swipeleft swiperight", function (ev) {
            var firstBlockC = document.getElementById('first');
            var secondBlockC = document.getElementById('second');
            var thirdBlockC = document.getElementById('third');

            var oneBlockC = document.getElementById('1');
            var twoBlockC = document.getElementById('2');
            var threeBlockC = document.getElementById('3');

            // console.log("HERE :: ",ev)
            console.log("HERE :: ", ev.type)
            elementt = document.getElementsByClassName("dotactiveS")[0];
            idd = elementt.id;
            console.log("My iDD :: ", idd)



            if (ev.type === 'swipeleft') {

                if (idd === '1') {

                    firstBlockC.style.display = 'none';
                    secondBlockC.style.display = 'flex';
                    thirdBlockC.style.display = 'none';

                    oneBlockC.classList.remove('dotactiveS');
                    twoBlockC.classList.add('dotactiveS');
                    threeBlockC.classList.remove('dotactiveS');

                    idchoose = "second";

                } else if (idd === '2') {
                    firstBlockC.style.display = 'none';
                    secondBlockC.style.display = 'none';
                    thirdBlockC.style.display = 'flex';

                    oneBlockC.classList.remove('dotactiveS');
                    twoBlockC.classList.remove('dotactiveS');
                    threeBlockC.classList.add('dotactiveS');

                    idchoose = "third";

                } else if (idd === '3') {
                    firstBlockC.style.display = 'flex';
                    secondBlockC.style.display = 'none';
                    thirdBlockC.style.display = 'none';

                    oneBlockC.classList.add('dotactiveS');
                    twoBlockC.classList.remove('dotactiveS');
                    threeBlockC.classList.remove('dotactiveS');

                    idchoose = "first";


                }

            }

            if (ev.type === 'swiperight') {
                if (idd === '1') {
                    firstBlockC.style.display = 'none';
                    secondBlockC.style.display = 'none';
                    thirdBlockC.style.display = 'flex';

                    oneBlockC.classList.remove('dotactiveS');
                    twoBlockC.classList.remove('dotactiveS');
                    threeBlockC.classList.add('dotactiveS');

                    idchoose = "third";

                } else if (idd === '2') {
                    firstBlockC.style.display = 'flex';
                    secondBlockC.style.display = 'none';
                    thirdBlockC.style.display = 'none';

                    oneBlockC.classList.add('dotactiveS');
                    twoBlockC.classList.remove('dotactiveS');
                    threeBlockC.classList.remove('dotactiveS');

                    idchoose = "first";

                } else if (idd === '3') {
                    firstBlockC.style.display = 'none';
                    secondBlockC.style.display = 'flex';
                    thirdBlockC.style.display = 'none';

                    oneBlockC.classList.remove('dotactiveS');
                    twoBlockC.classList.add('dotactiveS');
                    threeBlockC.classList.remove('dotactiveS');

                    idchoose = "second";

                }

            }

        });
    }















}

var mySwipeFive2 = document.getElementById('swipeMyFive2');
if (mySwipeFive2) {
    var mySwipeFive2 = new Hammer(mySwipeFive2, {
        touchAction: "pan-y"
    });

    mySwipeFive2.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });

    var idV2 = '';

    mySwipeFive2.on("swipeleft swiperight", function (ev) {
        var firstFiveBlock = document.getElementById('first52');
        var secondFiveBlock = document.getElementById('second52');
        var thirdFiveBlock = document.getElementById('third52');
        var fourFiveBlock = document.getElementById('four52');
        var fiveFiveBlock = document.getElementById('five52');

        var oneBlockFive = document.getElementById('1V2');
        var twoBlockFive = document.getElementById('2V2');
        var threeBlockFive = document.getElementById('3V2');
        var foBlockFive = document.getElementById('4V2');
        var fiBlockFive = document.getElementById('5V2');
        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        element = document.getElementsByClassName("dotactiveclients")[0];
        // element = document.querySelector('[data-type="11"]');
        console.log("My Element :: ", element)
        idV2 = element.id;
        console.log("My iD :: ", idV2)
        if (ev.type === 'swipeleft') {

            if (idV2 === '1V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'flex';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.add('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "second52";



            } else if (idV2 === '2V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'flex';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.add('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "third52";


            } else if (idV2 === '3V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'flex';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.add('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "four52";


            } else if (idV2 === '4V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'flex';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.add('dotactiveclients');

                idclients1 = "five52";


            } else if (idV2 === '5V2') {

                firstFiveBlock.style.display = 'flex';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.add('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "first52";


            }

        }

        if (ev.type === 'swiperight') {

            if (idV2 === '1V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'flex';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.add('dotactiveclients');

                idclients1 = "five52";



            } else if (idV2 === '2V2') {
                firstFiveBlock.style.display = 'flex';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.add('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "first52";


            } else if (idV2 === '3V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'flex';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.add('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "second52";


            } else if (idV2 === '4V2') {
                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'flex';
                fourFiveBlock.style.display = 'none';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.add('dotactiveclients');
                foBlockFive.classList.remove('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "third52";


            } else if (idV2 === '5V2') {

                firstFiveBlock.style.display = 'none';
                secondFiveBlock.style.display = 'none';
                thirdFiveBlock.style.display = 'none';
                fourFiveBlock.style.display = 'flex';
                fiveFiveBlock.style.display = 'none';

                oneBlockFive.classList.remove('dotactiveclients');
                twoBlockFive.classList.remove('dotactiveclients');
                threeBlockFive.classList.remove('dotactiveclients');
                foBlockFive.classList.add('dotactiveclients');
                fiBlockFive.classList.remove('dotactiveclients');

                idclients1 = "four52";


            }

        }
    });
}


var mySwipeTwo1 = document.getElementById('swipeMyTwo1');
if (mySwipeTwo1) {
    var mySwipeeTwo1 = new Hammer(mySwipeTwo1, {
        touchAction: "pan-y"
    });

    mySwipeeTwo1.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });


    var id2test = '';


    mySwipeeTwo1.on("swipeleft swiperight", function (ev) {
        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        elementt = document.getElementsByClassName("dotactivetest")[0];
        id2test = elementt.id;
        console.log("My iDD :: ", id2test)

        var firstBlock2 = document.getElementById('first2test');
        var secondBlock2 = document.getElementById('second2test');

        var oneBlock2 = document.getElementById('12test');
        var twoBlock2 = document.getElementById('22test');


        if (ev.type === 'swipeleft') {

            if (id2test === '12test') {

                firstBlock2.style.display = 'none';
                secondBlock2.style.display = 'flex';

                oneBlock2.classList.remove('dotactivetest');
                twoBlock2.classList.add('dotactivetest');
                idtestimonials = "second2test";



            } else if (id2test === '22test') {
                firstBlock2.style.display = 'flex';
                secondBlock2.style.display = 'none';

                oneBlock2.classList.add('dotactivetest');
                twoBlock2.classList.remove('dotactivetest');
                idtestimonials = "first2test";

            }
        }

        if (ev.type === 'swiperight') {
            if (id2test === '12test') {

                firstBlock2.style.display = 'none';
                secondBlock2.style.display = 'flex';

                oneBlock2.classList.remove('dotactivetest');
                twoBlock2.classList.add('dotactivetest');

                idtestimonials = "second2test";



            } else if (id2test === '22test') {
                firstBlock2.style.display = 'flex';
                secondBlock2.style.display = 'none';

                oneBlock2.classList.add('dotactivetest');
                twoBlock2.classList.remove('dotactivetest');

                idtestimonials = "first2test";

            }

        }

    });
}

var mySwipe2 = document.getElementById('swipeChoose2');
if (mySwipe2) {
    var mySwipee2 = new Hammer(mySwipe2, {
        touchAction: "pan-y"
    });

    mySwipee2.get('pan').set({
        direction: Hammer.DIRECTION_ALL
    });

    var id22 = '';


    mySwipee2.on("swipeleft swiperight", function (ev) {
        var firstBlock22 = document.getElementById('firstred');
        var secondBlock22 = document.getElementById('secondred');
        var thirdBlock22 = document.getElementById('thirdred');

        var oneBlock22 = document.getElementById('1red');
        var twoBlock22 = document.getElementById('2red');
        var threeBlock22 = document.getElementById('3red');

        // console.log("HERE :: ",ev)
        console.log("HERE :: ", ev.type)
        elementt = document.getElementsByClassName("dotredactive")[0];
        id22 = elementt.id;
        console.log("My iDD :: ", id22)



        if (ev.type === 'swipeleft') {

            if (id22 === '1red') {

                firstBlock22.style.display = 'none';
                secondBlock22.style.display = 'flex';
                thirdBlock22.style.display = 'none';

                oneBlock22.classList.remove('dotredactive');
                twoBlock22.classList.add('dotredactive');
                threeBlock22.classList.remove('dotredactive');

            } else if (id22 === '2red') {
                firstBlock22.style.display = 'none';
                secondBlock22.style.display = 'none';
                thirdBlock22.style.display = 'flex';

                oneBlock22.classList.remove('dotredactive');
                twoBlock22.classList.remove('dotredactive');
                threeBlock22.classList.add('dotredactive');
            } else if (id22 === '3red') {
                firstBlock22.style.display = 'flex';
                secondBlock22.style.display = 'none';
                thirdBlock22.style.display = 'none';

                oneBlock22.classList.add('dotredactive');
                twoBlock22.classList.remove('dotredactive');
                threeBlock22.classList.remove('dotredactive');

            }

        }

        if (ev.type === 'swiperight') {
            if (id22 === '1red') {
                firstBlock22.style.display = 'none';
                secondBlock22.style.display = 'none';
                thirdBlock22.style.display = 'flex';

                oneBlock22.classList.remove('dotredactive');
                twoBlock22.classList.remove('dotredactive');
                threeBlock22.classList.add('dotredactive');
            } else if (id22 === '2red') {
                firstBlock22.style.display = 'flex';
                secondBlock22.style.display = 'none';
                thirdBlock22.style.display = 'none';

                oneBlock22.classList.add('dotredactive');
                twoBlock22.classList.remove('dotredactive');
                threeBlock22.classList.remove('dotredactive');
            } else if (id22 === '3red') {
                firstBlock22.style.display = 'none';
                secondBlock22.style.display = 'flex';
                thirdBlock22.style.display = 'none';

                oneBlock22.classList.remove('dotredactive');
                twoBlock22.classList.add('dotredactive');
                threeBlock22.classList.remove('dotredactive');
            }

        }

    });
}
// end analytics choose section manual coding
// start schedule section
window.isMobile = function isMobile() {
    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)) {
        return true;
    } else {
        return false;
    }
}
window.OpenSchedule = function OpenSchedule() {

    if (!isMobile()) {
        var btn = document.getElementById('Demoposition');
        btn.classList.toggle('activedemo');
    }
}

window.CloseSchedule = function CloseSchedule() {
    if (!isMobile()) {
        var btn = document.getElementById('Demoposition');
        btn.classList.toggle('activedemo');
    }
    // document.getElementById("Demoposition").classList.toggle("activedemo");
}



// end schedule section